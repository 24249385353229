var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "v-application vuetify-wrapper" }, [
    _c("div", { staticClass: "filter-container" }, [
      _c("p", { staticClass: "border-b py-2 title" }, [_vm._v("Filter ")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "inline-flex border-b py-4 px-8" },
        [
          _c("div", { staticClass: "pt-5" }, [
            _vm._v("In this view, show records where"),
          ]),
          _vm._v(" "),
          _c("v-select", {
            staticClass: "w-200",
            attrs: {
              items: _vm.fieldOptions,
              outlined: "",
              "hide-details": "",
              dense: "",
            },
            on: { change: _vm.onFieldsChanged },
            model: {
              value: _vm.field,
              callback: function ($$v) {
                _vm.field = $$v
              },
              expression: "field",
            },
          }),
          _vm._v(" "),
          _c("v-select", {
            staticClass: "w-200",
            attrs: {
              items: _vm.filterOptions,
              disabled: _vm.isFilterTypeDisabled,
              outlined: "",
              "hide-details": "",
              dense: "",
            },
            model: {
              value: _vm.filterType,
              callback: function ($$v) {
                _vm.filterType = $$v
              },
              expression: "filterType",
            },
          }),
          _vm._v(" "),
          _vm.field === "title"
            ? _c("v-text-field", {
                staticClass: "w-400",
                attrs: {
                  placeholder: "Enter keyword",
                  outlined: "",
                  dense: "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.keyword,
                  callback: function ($$v) {
                    _vm.keyword = $$v
                  },
                  expression: "keyword",
                },
              })
            : _vm.field === "wf_state"
            ? _c("v-select", {
                staticClass: "w-400",
                attrs: {
                  items: _vm.statusOptions,
                  outlined: "",
                  "hide-details": "",
                  dense: "",
                },
                model: {
                  value: _vm.keyword,
                  callback: function ($$v) {
                    _vm.keyword = $$v
                  },
                  expression: "keyword",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-center" },
        [
          _c(
            "v-btn",
            {
              attrs: { outlined: "", dense: "", "hide-details": "" },
              on: { click: _vm.onFilterBtnClicked },
            },
            [
              _c("v-icon", { attrs: { small: "", start: "" } }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.isFiltered ? "mdi-filter-check" : "mdi-filter") +
                    "\n                "
                ),
              ]),
              _vm._v("\n                Filter\n            "),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: { "hide-details": "", plain: "" },
              on: { click: _vm.clearFilter },
            },
            [
              _c("v-icon", { attrs: { small: "", start: "" } }, [
                _vm._v("mdi-close"),
              ]),
              _vm._v("\n                Clear all filters\n            "),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "filter-container" },
      [
        _c("v-data-table", {
          staticClass: "v-application--is-ltr",
          attrs: {
            headers: _vm.headers,
            items: _vm.ticketsList,
            "items-per-page": 10,
            page: _vm.pageNumber,
            options: _vm.tableOptions,
            "server-items-length": _vm.ticketsCount,
            "item-key": "_id",
            loading: _vm.isLoading,
            expanded: _vm.expanded,
            "no-data-text": "Keine Suchergebnisse",
            "loading-text": "Wird geladen...",
            "disable-sort": "",
            "footer-props": {
              "items-per-page-options": [5, 10, 25, 50],
              "show-first-last-page": true,
              "show-current-page": true,
              "items-per-page-text": "Zeilen pro Seite",
            },
          },
          on: {
            "update:page": function ($event) {
              _vm.pageNumber = $event
            },
            "update:options": [
              function ($event) {
                _vm.tableOptions = $event
              },
              _vm.onOptionsChange,
            ],
            "update:expanded": function ($event) {
              _vm.expanded = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "item",
              fn: function ({ item, expand, isExpanded }) {
                return [
                  _c(
                    "tr",
                    { staticClass: "py-4", attrs: { "data-id": item?._id } },
                    [
                      _c("td", [
                        _vm._v(
                          "\n                        " +
                            _vm._s(item?._id) +
                            "\n                    "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.workflow_states[item.wf_state]) +
                            "\n                    "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "col-md-3" }, [
                        _c("div", { staticClass: "flex-col" }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: `/pharmacies/${item?.ref_pharmacy?._id}`,
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(item?.ref_pharmacy?.name || "") +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: `/pharmacists/${item?.ref_pharmacist?._id}`,
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    item?.ref_pharmacist?.firstname || ""
                                  ) +
                                  " " +
                                  _vm._s(item?.ref_pharmacist?.lastname || "") +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: `/pharmacies/${item.ref_pharmacy?._id}/${item?.ref_appointment?._id}/tickets`,
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(item?.ref_appointment?.date || "") +
                                  "\n                            "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "col-md-5" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(item.title) +
                            "\n                    "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "col-md-1" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function ($event) {
                                  return expand(!isExpanded)
                                },
                              },
                            },
                            [
                              _c("v-icon", [
                                _vm._v(
                                  _vm._s(
                                    isExpanded
                                      ? "mdi-chevron-up"
                                      : "mdi-chevron-down"
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("td", { staticClass: "col-md-1" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.categories[item.category]) +
                            "\n                    "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "col-md-3" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.getMomentDate(item.created)) +
                            "\n                    "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "col-md-2" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: `/pharmacists/${item?.ref_pharmacist?._id}`,
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(item?.creator?.firstname) +
                                "\n                        "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "col-md-2" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(item?.resubmit) +
                            "\n                    "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "col-md-2" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: `/crm/${item?._id}/edit`,
                              target: "_blank",
                            },
                          },
                          [
                            _c(
                              "v-icon",
                              { attrs: { dark: "", color: "primary" } },
                              [
                                _vm._v(
                                  "\n                                mdi-pencil\n                            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                ]
              },
            },
            {
              key: "expanded-item",
              fn: function ({ item }) {
                return [
                  _c("tr", { attrs: { "data-id": item?._id } }, [
                    _c("td"),
                    _vm._v(" "),
                    _c("td"),
                    _vm._v(" "),
                    _c("td", { staticClass: "col-md-3" }),
                    _vm._v(" "),
                    _c("td", { staticClass: "col-md-5" }, [
                      _c("div", { staticClass: "p-4" }, [
                        _c("strong", [_vm._v("Beschreibung:")]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(item.description))]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "col-md-1" }),
                    _vm._v(" "),
                    _c("td", { staticClass: "col-md-1" }),
                    _vm._v(" "),
                    _c("td", { staticClass: "col-md-3" }),
                    _vm._v(" "),
                    _c("td", { staticClass: "col-md-2" }),
                    _vm._v(" "),
                    _c("td", { staticClass: "col-md-2" }),
                    _vm._v(" "),
                    _c("td", { staticClass: "col-md-2" }),
                  ]),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }