<template>
<div class="v-application vuetify-wrapper">
    <div class="filter-container">
        <p class="border-b py-2 title">Filter </p>
        <div class="inline-flex border-b py-4 px-8">
            <div class="pt-5">In this view, show records where</div>
            <v-select
                class="w-200"
                :items="fieldOptions"
                v-model="field"
                @change="onFieldsChanged"
                outlined
                hide-details
                dense
            ></v-select>
            <v-select
                class="w-200"
                :items="filterOptions"
                :disabled="isFilterTypeDisabled"
                v-model="filterType"
                outlined
                hide-details
                dense
            ></v-select>
            <v-text-field
                v-if="field==='title'"
                class="w-400"
                v-model="keyword"
                placeholder="Enter keyword"
                outlined
                dense
                hide-details
            ></v-text-field>
            <v-select
                v-else-if="field==='wf_state'"
                class="w-400"
                v-model="keyword"
                :items="statusOptions"
                outlined
                hide-details
                dense
            ></v-select>
        </div>
        <div class="flex-center">
            <v-btn @click="onFilterBtnClicked" outlined dense hide-details>
                <v-icon small start>
                    {{ isFiltered ? 'mdi-filter-check' : 'mdi-filter'}}
                </v-icon>
                Filter
            </v-btn>
            <v-btn @click="clearFilter" hide-details plain>
                <v-icon small start>mdi-close</v-icon>
                Clear all filters
            </v-btn>
        </div>
    </div>
    <div class="filter-container">
        <v-data-table
            :headers="headers"
            :items="ticketsList"
            :items-per-page="10"
            :page.sync="pageNumber"
            :options.sync="tableOptions"
            :server-items-length="ticketsCount"
            item-key="_id"
            :loading="isLoading"
            :expanded.sync="expanded"
            @update:options="onOptionsChange"
            class="v-application--is-ltr"
            no-data-text="Keine Suchergebnisse"
            loading-text="Wird geladen..."
            disable-sort
            :footer-props="{'items-per-page-options':[5, 10, 25, 50], 'show-first-last-page': true, 'show-current-page': true, 'items-per-page-text': 'Zeilen pro Seite'}"
        >
            <template v-slot:item="{ item, expand, isExpanded }">
                <tr :data-id="item?._id" class="py-4">
                    <td>
                        {{item?._id}}
                    </td>
                    <td>
                        {{workflow_states[item.wf_state]}}
                    </td>
                    <td class="col-md-3">
                        <div class="flex-col">
                            <a :href="`/pharmacies/${item?.ref_pharmacy?._id}`" target="_blank">
                                {{item?.ref_pharmacy?.name || ""}}
                            </a>
                            <a :href="`/pharmacists/${item?.ref_pharmacist?._id}`" target="_blank">
                                {{item?.ref_pharmacist?.firstname || ""}} {{item?.ref_pharmacist?.lastname || ""}}
                            </a>
                            <a :href="`/pharmacies/${item.ref_pharmacy?._id}/${item?.ref_appointment?._id}/tickets`" target="_blank">
                                {{item?.ref_appointment?.date || ""}}
                            </a>
                        </div>
                    </td>
                    <td class="col-md-5">
                        {{item.title}}
                    </td>
                    <td class="col-md-1">
                        <v-btn icon @click="expand(!isExpanded)">
                            <v-icon>{{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down'
                                }}</v-icon>
                        </v-btn>
                    </td>
                    <td class="col-md-1">
                        {{categories[item.category]}}
                    </td>
                    <td class="col-md-3">
                        {{ getMomentDate(item.created) }}
                    </td>
                    <td class="col-md-2">
                        <a :href="`/pharmacists/${item?.ref_pharmacist?._id}`" target="_blank">
                            {{item?.creator?.firstname}}
                        </a>
                    </td>
                    <td class="col-md-2">
                        {{item?.resubmit}}
                    </td>
                    <td class="col-md-2">
                        <a :href="`/crm/${item?._id}/edit`" target="_blank">
                            <v-icon dark color="primary">
                                mdi-pencil
                            </v-icon>
                        </a>
                    </td>
                </tr>
            </template>

            <template v-slot:expanded-item="{ item }">
                <tr :data-id="item?._id">
                    <td></td>
                    <td></td>
                    <td class="col-md-3"></td>
                    <td class="col-md-5">
                        <div class="p-4">
                            <strong>Beschreibung:</strong>
                            <div>{{ item.description }}</div>
                        </div>
                    </td>
                    <td class="col-md-1"></td>
                    <td class="col-md-1"></td>
                    <td class="col-md-3"></td>
                    <td class="col-md-2"></td>
                    <td class="col-md-2"></td>
                    <td class="col-md-2"></td>
                </tr>
            </template>
        </v-data-table>
    </div>

</div>
</template>

<script>
import axios from "axios";
import moment from 'moment';
import { mapMutations } from 'vuex';

export default {
    props: {
        tickets_json: String,
        workflow_states_json: String,
        categories_json: String,
        pharmacy_id: String,
        appointment_id: String
    },
    data: () => ({
        field: "title",
        filterType: "contains",
        keyword: "",
        expanded: [],
        ticketsList: [],
        ticketsCount: 0,
        singleExpand: false,
        pageType: "",
        pageNumber: 1,
        tableOptions: {},
        isFiltered: false,
        isLoading: false,
        statusOptions: [{
            text: 'Alle',
            value: 'all'
        }, {
            text: 'Offen',
            value: 'open'
        }, {
            text: 'Geschlossen',
            value: 'closed'
        }],
        filterOptions: [{
            text: 'contains',
            value: 'contains'
        }, {
            text: 'does not contain',
            value: 'not'
        }],
        fieldOptions: [{
            text: 'Title',
            value: 'title'
        }, {
            text: 'Status',
            value: 'wf_state'
        }, ],
        headers: [{
                text: '#',
                value: '_id'
            },
            {
                text: 'Status',
                value: 'wf_state'
            },
            {
                text: 'Referenz',
                value: 'ref_appointment_id'
            },
            {
                text: 'Titel',
                value: 'title'
            },
            {
                text: '',
                value: 'description'
            },
            {
                text: 'Typ',
                value: 'category'
            },
            {
                text: 'Datum',
                value: 'created'
            },
            {
                text: 'Ersteller',
                value: 'creator'
            },
            {
                text: 'Wiedervorlage',
                value: 'resubmit'
            },
            {
                text: 'Aktion',
                value: 'edit'
            }
        ],
    }),
    methods: {
        onFieldsChanged(field) {
            if (field === 'wf_state') {
                this.filterType = "contains"
                this.keyword = "all"
            }
        },
        async filterTickets(page = 1, pageSize = 10) {
            try {
                this.isLoading = true;
                let response
                let payload = this.generatePayload(page, pageSize)
                response = await axios.post(`/api/tickets`, payload);

                this.ticketsList = response.data.tickets;
                this.ticketsCount = response.data.count;
                this.tableOptions.page = page;
                this.tableOptions.itemsPerPage = pageSize;
                this.isLoading = false;
            } catch (error) {
                this.alertError();
            } finally {
                this.isLoading = false;
            }
        },
        async onFilterBtnClicked(){
            await this.filterTickets()
            this.isFiltered = true
        },
        async onOptionsChange({
            page,
            itemsPerPage
        }) {
            await this.filterTickets(page, itemsPerPage);
        },
        generatePayload(page, pageSize) {
            let payload = {}
            if (this.appointment_id) {
                payload.appointment_id = this.appointment_id
            }
            if (this.pharmacy_id) {
                payload.pharmacy_id = this.pharmacy_id
            }
            if ((this.field === 'title' || this.field === 'wf_state') && this.keyword) {
                payload.keyword = this.keyword
                payload.field = this.field
                payload.filter_type = this.filterType
            }
            payload.type = this.pageType
            payload.page = page
            payload.page_size = pageSize
            return payload
        },
        async clearFilter() {
            this.field = "title";
            this.filterType = "contains";
            this.keyword = "";
            this.isFiltered = false;
            await this.filterTickets();
        },
        getMomentDate(created) {
            return moment.utc(created).local().format('DD-MM-yyyy HH:mm')
        },
        ...mapMutations({
            alertError: 'alert/error',
        })
    },
    computed: {
        tickets() {
            return JSON.parse(this.tickets_json);
        },
        workflow_states() {
            return JSON.parse(this.workflow_states_json);
        },
        categories() {
            return JSON.parse(this.categories_json);
        },
        isFilterTypeDisabled() {
            return this.field === 'wf_state'
        },
        dateMoment() {
            return moment.utc(this.value.date);
        }
    },
    async created() {
        const type = new URLSearchParams(window.location.search).get('type');
        this.pageType = type || "";
        await this.filterTickets();
    }
};
</script>

<style scoped>
.container {
    width: 100%;
}

.filter-container {
    border: 1px solid #EEE;
    background: #FFFFFF;
    padding: 1rem;
    padding-bottom: 0.5rem;
    border-radius: 8px;
    margin-bottom: 8px;
}

.inline-flex {
    width: 100%;
    display: inline-flex;
    gap: 8px;
    align-items: center;
}

.title {
    font-size: 24px;
    font-weight: 600;
}

.px-8 {
    padding-left: 48px;
    padding-right: 48px;
}

.py-4 {
    padding: 16px 0;
}

.py-2 {
    padding: 4px 0;
}

.pt-5 {
    padding-top: 10px;
}

.p-4 {
    padding: 16px !important;
}

.w-200 {
    width: 200px;
    max-width: 200px;
}

.w-400 {
    width: 400px;
    max-width: 400px;
}

.flex-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.border-b {
    border-bottom: 1px solid #E0E0E0;
}

th {
    vertical-align: middle;
    padding: 0 10px !important;
    border-bottom: none !important;
    font-size: 15px !important;
    color: black !important;
}
</style>
